import React, { useEffect, useState, useContext } from 'react'
import { useFormContext } from "react-hook-form"
import { Grid } from '@material-ui/core'
import FileForUserUrls from 'src/apiUrls/FileUserApiUrls.js'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls"
import PartyConfigApiUrls from 'src/apiUrls/PartyConfigApiUrls'
import Namespace from 'src/constants/locale/Namespace'
import FileForUserKeys from '../../constants/locale/key/FileForUser'
import FileForUserContext from 'src/contexts/FileForUserContext'
import makeValidationSchema from './MakeValidationSchema'
import CngBackdrop from 'src/components/cngcomponents/CngBackDrop'
import FileForUserButtonComponent from './component/FileForUserButtonComponent'
import CngSection from '../../components/cngcomponents/CngSection'
import { fetchUser } from "src/views/userprofile/UserProfileService.js"
import { GetLoggedInUserPartyName, GetLoggedInUserPartyId, FileForUserGetUserId, FileForUserRemoveAll, FileForUserGetPartyId } from 'src/common/FileForUserCommon'

import {
  components,
  DataFlattener,
  useServices,
  useTranslation,
  constants
} from 'cng-web-lib'

const {
  form: { field: { CngTextField } },
  table: { useFetchCodeMaintenanceLookup },
  CngGridItem
} = components

const { filter: { EQUAL } } = constants

const FILE_FOR_USER_PARTY_NAME = 'fileForUserPartyName'
const FILE_FOR_USER_BY_ADMIN = 'fileForUserByAdmin'
const FILE_FOR_USER_DETAILS = 'fileForUserDetails'

const DEFAULT_INITIAL_VALUES = Object.freeze({ username: "" })

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { getValues, trigger } = useFormContext();
  const { securedSendRequest, fetchRecords } = useServices();
  const { fileForUserValue, setFileForUserValue } = useContext(FileForUserContext)
  const { translate } = useTranslation(Namespace.FILE_FOR_USER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [loadingState, setLoadingState] = new useState(false);
  const [isSuperParty, setSuperParty] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({})  
  const [user, setUser] = useState({})  

  useEffect(() => {
    Promise.all([
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(([billingSuperParty]) => {
      Object.keys(billingSuperParty).forEach(e => {
        if (e == GetLoggedInUserPartyName()) {
          setSuperParty(true);
        }
      })
    })
  }, [])

  useEffect(() => {
    if(user.id!=undefined && user.id!=null){
      sessionStorage.setItem('nangUserProfile', JSON.stringify(user))

      fetchRecords.execute(PartyConfigApiUrls.GET,
        { filters: [{ field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }] },
        (res) => {
          if(res.content.length>0){
            sessionStorage.setItem('nangPartyConfig', JSON.stringify(res.content))
          }
        });
    }
  },[user])

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.TITLE
    )

    let userId = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.USER_ID
    )

    let button = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.BUTTON
    )

    let helpText1 = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.HELP_TEXT_1
    )

    let errorMessage1 = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_1
    )

    let errorMessage2 = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_2
    )

    let errorMessageTPRUser = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_TPR_USER
    )

    let errorMessageTPRUserPreference = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_TPR_USER_PREFERENCE
    )

    let successMessage = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.SUCCESS_MESSAGE
    )

    let errorMessageFileForUserProfile = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_FILE_FOR_USER_PROFILE
    )

    let errorMessageFileForUserCorpId = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_FILE_FOR_USER_CORP_ID
    )

    let errorMessageLoggedInUserCorpId = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_LOGGED_IN_USER_CORP_ID
    )

    let errorMessageLoggedInUserProfile = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_LOGGED_IN_USER_PROFILE
    )

    let errorMessageRetrieveData = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_RETRIEVE_DATA
    )

    let errorMessageCorpIdNotMatch = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_CORP_ID_NOT_MATCH
    )

    let errorMessageChildCorpIdNotMatch = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_CHILD_CORP_ID_NOT_MATCH
    )

    let errorMessageServiceBureauFlag = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_SERVICE_BUREAU_FLAG
    )

    let errorMessageServiceBureauChildCorpIdFlag = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_SERVICE_BUREAU_CHILD_CORP_ID_FLAG
    )

    let errorMessageTPRUserDetails = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_TPR_USER_DETAILS
    )

    let errorMessageTPRUserDetailsInactive = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_TPR_USER_DETAILS_INACTIVE
    )
    
    return {
      title,
      userId,
      button,
      helpText1,
      errorMessage1,
      errorMessage2,
      errorMessageTPRUser,
      errorMessageTPRUserPreference,
      successMessage,
      errorMessageFileForUserProfile,
      errorMessageFileForUserCorpId,
      errorMessageLoggedInUserCorpId,
      errorMessageLoggedInUserProfile,
      errorMessageRetrieveData,
      errorMessageCorpIdNotMatch,
      errorMessageChildCorpIdNotMatch,
      errorMessageServiceBureauFlag,
      errorMessageServiceBureauChildCorpIdFlag,
      errorMessageTPRUserDetails,
      errorMessageTPRUserDetailsInactive
    }
  }

  function onSubmit() {
    const result = trigger()

    result.then(function (result) {
      if (result == true) {

        setLoadingState(true)
        let username = getValues("username")

        if (username.toUpperCase() != FileForUserGetUserId().toUpperCase()) {

          function onSuccess(response) {
            let data = response.data

            if (data !== null && data !== undefined) {
              let errorMessages = data.errorMessages

              if (errorMessages === null || errorMessages === undefined) {
                let content = data.content;

                if (content != null && content.length > 0) {
                  getUserDetails(content[0].id)
                } else {
                  setLoadingState(false)
                  showNotification("error", translatedTextsObject.errorMessageTPRUser)
                }
              }
            }
          }

          function onError(error) {
            setLoadingState(false)
            showNotification("error", translatedTextsObject.errorMessageTPRUser)
          }

          function onComplete() { }

          let url = FileForUserUrls.GET_TPR_USER;
          const formData = {
            baseFilterDTO: {
              filterType: "AND",
              filterProperties: [{ fieldName: "loginId", operatorType: "EQUAL", value1: username }],
              sortProperties: []
            }
          }
          const config = {}

          securedSendRequest.execute('POST', url, formData, onSuccess, onError, onComplete, config, null);

        } else { 
          //file for user same as logged user, remove file for user feature
          showNotification("success", translatedTextsObject.successMessage)
          setFileForUserValue({})
          FileForUserRemoveAll()
          sessionStorage.removeItem(FILE_FOR_USER_BY_ADMIN)
          sessionStorage.removeItem(FILE_FOR_USER_PARTY_NAME)
          setLoadingState(false)
          fetchUser(setUser, securedSendRequest,null); 
        }
      }
    });
  }

  function getUserDetails(userProfileId) {

    function onSuccess(response) {
      let isActiveUser = true;
      let data = response.data;

      if (data !== null && data !== undefined) {
        let errorMessages = data.errorMessages

        if (errorMessages === null || errorMessages === undefined) {
          let content = data.content;

          if (content != null && content.length > 0) {

            const suspendedUserDetails = content.find(userDetails => userDetails.status === 'SUSPENDED' || userDetails.status === 'DE_REGISTERED');
            if(suspendedUserDetails!=null){
              setLoadingState(false)
              showNotification("error", translatedTextsObject.errorMessageTPRUserDetailsInactive)
              isActiveUser = false;
            }
          }
        }
      }else{
        showNotification("error", translatedTextsObject.errorMessageTPRUserDetails)
      }

      if(isActiveUser == true){
        getUserPreference(userProfileId)
      }
    }

    function onError(error) {
      setLoadingState(false)
      console.log(error)
      showNotification("error", translatedTextsObject.errorMessageTPRUserDetails)
      return false
    }

    function onComplete() { }

    let url = FileForUserUrls.GET_TPR_USER_DETAILS;
    const formData = {
      baseFilterDTO: {
        filterType: "AND",
        filterProperties: [{ fieldName: "userProfileId", operatorType: "EQUAL", value1: userProfileId }],
        sortProperties: []
      }
    }
    const config = {}

    securedSendRequest.execute('POST', url, formData, onSuccess, onError, onComplete, config, null);
  }

  function getUserPreference(userProfileId) {
    function onSuccess(response) {
      let data = response.data;

      if (data !== null && data !== undefined) {
        let errorMessages = data.errorMessages

        if (errorMessages === null || errorMessages === undefined) {
          let content = data.content;

          if (content != null && content.length > 0) {
            let tprUserDetails = content[0].tprUserDetail;

            if (tprUserDetails != null && tprUserDetails != undefined) {
              //super admin account
              if (isSuperParty) {
                let fileForUserDetails = {
                  fileForUserId: tprUserDetails.id,
                  fileForUserPartyId: tprUserDetails.partyId,
                  fileForUserLoginId: tprUserDetails.userProfile.loginId
                }
                console.log("Super Party tprUserDetails", fileForUserDetails)
                sessionStorage.setItem(FILE_FOR_USER_BY_ADMIN, true)
                sessionStorage.setItem(FILE_FOR_USER_DETAILS, JSON.stringify(fileForUserDetails))
                sessionStorage.setItem(FILE_FOR_USER_PARTY_NAME, tprUserDetails?.party?.name)

                showNotification("success", translatedTextsObject.successMessage)
                setFileForUserValue(fileForUserDetails)
                setLoadingState(false)    
                fetchUser(setUser, securedSendRequest,fileForUserDetails); 
                
              } else {
                let currentLoggedInUserPartyId = GetLoggedInUserPartyId();
                //same party
                if (tprUserDetails.partyId == currentLoggedInUserPartyId) {
                  let fileForUserDetails = {
                    fileForUserId: tprUserDetails.id,
                    fileForUserPartyId: tprUserDetails.partyId,
                    fileForUserLoginId: tprUserDetails.userProfile.loginId
                  }
                  console.log("Same Party tprUserDetails",fileForUserDetails)
                  sessionStorage.setItem(FILE_FOR_USER_DETAILS, JSON.stringify(fileForUserDetails))
                  sessionStorage.setItem(FILE_FOR_USER_PARTY_NAME, tprUserDetails?.party?.name)

                  showNotification("success", translatedTextsObject.successMessage)
                  setFileForUserValue(fileForUserDetails)
                  setLoadingState(false)
                  fetchUser(setUser, securedSendRequest, fileForUserDetails); 

                } else { 
                  //check service bureau account
                  let inputFileForUserDetails = {
                    fileForUserId: tprUserDetails.id,
                    fileForUserPartyId: tprUserDetails.partyId,
                    fileForUserLoginId: tprUserDetails.userProfile.loginId
                  }
                  console.log("Service Bureau tprUserDetails")
                  getFileForUserCorpId(inputFileForUserDetails,tprUserDetails,setLoadingState);
                }
              }
            } else {
              setLoadingState(false)
              showNotification("error", translatedTextsObject.errorMessageTPRUserPreference)
            }
          }else{
            setLoadingState(false)
            showNotification("error", translatedTextsObject.errorMessageTPRUserPreference)
          }

        }
      }else{
        setLoadingState(false)
        showNotification("error", translatedTextsObject.errorMessageTPRUserPreference)
      }
    }

    function onError(error) {
      setLoadingState(false)
      showNotification("error", translatedTextsObject.errorMessageTPRUserPreference)
    }

    function onComplete() { }

    let url = FileForUserUrls.GET_TPR_USER_PREFERENCE;
    const formData = {
      baseFilterDTO: {
        filterType: "AND",
        filterProperties: [{ fieldName: "userProfileId", operatorType: "EQUAL", value1: userProfileId }],
        sortProperties: []
      }
    }
    const config = {}

    securedSendRequest.execute('POST', url, formData, onSuccess, onError, onComplete, config, null);
  }

  function getFileForUserCorpId(inputFileForUserDetails, tprUserDetails, setLoadingState) {
    function onSuccess(response) {
      let data = response.data;
      console.log("File For User Profile: {}",data)
      if (data !== null && data !== undefined) {
      
        let inputLoggedInUserDetails = {
          fileForUserId: null,
          fileForUserPartyId: null,
          fileForUserLoginId: FileForUserGetUserId()
        }

        getCurrentLoggedInUserCorpId(inputLoggedInUserDetails,data,tprUserDetails,setLoadingState);

      }else{
        setLoadingState(false)
        //file for user dont have user profile
        showNotification("error", translatedTextsObject.errorMessageFileForUserProfile+inputFileForUserDetails.fileForUserLoginId)
      }
    }
  
    function onError(error) {
      setLoadingState(false)
      showNotification("error", translatedTextsObject.errorMessageRetrieveData)
      console.log(error);
    }
  
    function onComplete() { }
    const config = {}
    securedSendRequest.execute('POST',UserProfileApiUrls.GET,inputFileForUserDetails, onSuccess, onError, onComplete,config,null);
  }

  function getCurrentLoggedInUserCorpId(inputLoggedInUserDetails,fileForUserProfile,tprUserDetails,setLoadingState){
    function onSuccess(response) {

      let data = response.data;
      console.log("Logged In User Profile: {}",data)
      if (data !== null && data !== undefined) {
        if (fileForUserProfile.corpid !== null && fileForUserProfile.corpid !== undefined && fileForUserProfile.corpid.length>0) {
          
           if(data.corpid!== null && data.corpid!== undefined && data.corpid.length>0){

            //handle the user have corpid only
            if(data.corpid[0].corpid == fileForUserProfile.corpid[0].corpid && (data.corpid[0].childCorpid==null || data.corpid[0].childCorpid==undefined || data.corpid[0].childCorpid=="")){


              if(data.corpid[0].serviceBureauFlag == true){
                let fileForUserDetails = {
                  fileForUserId: tprUserDetails.id,
                  fileForUserPartyId: tprUserDetails.partyId,
                  fileForUserLoginId: tprUserDetails.userProfile.loginId
                }

                console.log("Same Party tprUserDetails",fileForUserDetails)
                sessionStorage.setItem(FILE_FOR_USER_DETAILS, JSON.stringify(fileForUserDetails))
                sessionStorage.setItem(FILE_FOR_USER_PARTY_NAME, tprUserDetails?.party?.name)

                setFileForUserValue(fileForUserDetails)
                showNotification("success", translatedTextsObject.successMessage)
                fetchUser(setUser, securedSendRequest,fileForUserDetails); 

              } else { 
                showNotification("error", translatedTextsObject.errorMessageServiceBureauFlag)
              }
            } 
            //handle user have corpid and child corpid, child corpid will take priority
            //if user have child corpid then must match with file for user child corpid
            else if(data.corpid[0].childCorpid!=null && data.corpid[0].childCorpid!=undefined && data.corpid[0].childCorpid!="" && data.corpid[0].childCorpid == fileForUserProfile.corpid[0].childCorpid){ 
              if(data.corpid[0].serviceBureauFlag == true){
                let fileForUserDetails = {
                  fileForUserId: tprUserDetails.id,
                  fileForUserPartyId: tprUserDetails.partyId,
                  fileForUserLoginId: tprUserDetails.userProfile.loginId
                }

                console.log("Same Party Child Corpid tprUserDetails",fileForUserDetails)
                sessionStorage.setItem(FILE_FOR_USER_DETAILS, JSON.stringify(fileForUserDetails))
                sessionStorage.setItem(FILE_FOR_USER_PARTY_NAME, tprUserDetails?.party?.name)

                setFileForUserValue(fileForUserDetails)
                showNotification("success", translatedTextsObject.successMessage)
                fetchUser(setUser, securedSendRequest,fileForUserDetails); 
              }else{
                showNotification("error", translatedTextsObject.errorMessageServiceBureauFlag)
              }
            
            }else {  

              if(data.corpid[0].childCorpid!=null && data.corpid[0].childCorpid!=undefined && data.corpid[0].childCorpid!=""){
                //file for user child corpid not match
                showNotification("error", translatedTextsObject.errorMessageChildCorpIdNotMatch)
              }else
              //file for user corpid not match
              showNotification("error", translatedTextsObject.errorMessageCorpIdNotMatch)
            }
           } 
           else { 
            //logged in user dont have corpid
            showNotification("error", translatedTextsObject.errorMessageLoggedInUserCorpId+inputLoggedInUserDetails.fileForUserLoginId)
           }
        } else { 
          //file for user dont have corpid
          showNotification("error", translatedTextsObject.errorMessageFileForUserCorpId+tprUserDetails.userProfile.loginId)
        }
      } else { 
        //logged in user dont have corpid
        showNotification("error", translatedTextsObject.errorMessageLoggedInUserProfile+inputLoggedInUserDetails.fileForUserLoginId)
      }
       setLoadingState(false)
    }

    function onError(error) {
      showNotification("error", translatedTextsObject.errorMessageRetrieveData)
      console.log(error);
    }

    function onComplete() { }
    const config = {}

    securedSendRequest.execute('POST', UserProfileApiUrls.GET, inputLoggedInUserDetails, onSuccess, onError, onComplete, config, null);
  }

  return loadingState ? (
    <CngBackdrop loading />
  ) : (
    <CngSection title={translatedTextsObject.title}>
      <Grid container justify='flex-end' spacing={2}>
        <CngGridItem xs={12}>
          <Grid container spacing={2}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userId}>
              <CngTextField
                name='username'
                label={translatedTextsObject.userId}
                disabled={disabled}
              />
            </CngGridItem>
          </Grid>
        </CngGridItem>
        <CngGridItem xs='auto'>
          <FileForUserButtonComponent onSubmit={onSubmit} />
        </CngGridItem>
      </Grid>
    </CngSection>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties; 